import {
    REQUEST_STRINGS,
    RECEIVE_STRINGS,
    UPDATE_STRINGS,
    ERROR_STRINGS,
    TOGGLE_DEV_STRINGS
} from '../../types'
import {initialFetchState} from '../../../constants/defaultValues'
import isEmpty from "lodash/isEmpty"

export default (state = initialFetchState, action = {}) => {
    switch(action.type) {
        case REQUEST_STRINGS:
            return {
                ...state,
                isFetching: true,
                message: '',
                errors: {}
            }
        case RECEIVE_STRINGS:
            return {
                ...state,
                isFetching: false,
                data: {...state.data, ...action.payload},
                adminLoaded: action.admin,
                errors: {},
                message: action.message,
            }
        case UPDATE_STRINGS:
            let data = {...state.data}
            switch(action.updateType) {
                case 'create':
                case 'update':
                    data[`${action.payload.category_name}.${action.payload.identifier}`] = action.payload[action.locale]
                    break
                case 'delete':
                    delete data[`${action.payload.category_name}.${action.payload.identifier}`]
                    break
            }
            return {
                ...state,
                data,
                errors: {}
            }
        case ERROR_STRINGS:
            return {
                ...state,
                isFetching: false,
                errors: action.errors
            }
        case TOGGLE_DEV_STRINGS:
            const newState = {}
            if(isEmpty(state.original)){
                newState.original = state.data
                newState.data = {}
                Object.keys(state.data).map(key => {
                    newState.data[key] = key
                })
            }else{
                newState.original = {}
                newState.data = state.original
            }
            return {
                ...state,
                ...newState
            }
        default:
            return state
    }
}