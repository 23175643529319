import {defaultSidebar, storage, defaultCookieSettings} from '../../../constants/defaultValues'

import {
    CHANGE_SIDEBAR,
    CHANGE_WEB_SIDEBAR,
    SET_COOKIE_SETTINGS,
    INIT_COOKIE_SETTINGS,
    TOGGLE_COOKIE_SETTINGS,
    SET_LOCALE,
    SET_PRODUCT,
    SET_NOTIFICATION_READ,
    TOGGLE_LOGIN_FROM,
    TOGGLE_EXTERNAL_LOGIN_LOADING
} from '../../types'


const INIT_STATE_SIDEBAR = (localStorage.getItem(storage.sidebar) && ['', 'collapsed'].filter(x => x === localStorage.getItem(storage.sidebar)).length > 0) ? localStorage.getItem(storage.sidebar) : defaultSidebar

export const sidebar = (state = INIT_STATE_SIDEBAR, action) => {
    if (action.type === CHANGE_SIDEBAR) {
        localStorage.setItem(storage.sidebar, action.payload)
        return action.payload
    } else {
        return state
    }
}

const INIT_STATE_NOTIFICATIONS = localStorage.getItem(storage.notifications) ? JSON.parse(localStorage.getItem(storage.notifications)) : []

export const read_notifications = (state = INIT_STATE_NOTIFICATIONS, action) => {
    if (action.type === SET_NOTIFICATION_READ) {
        const n = [...state]
        n.push(action.payload)
        localStorage.setItem(storage.notifications, JSON.stringify(n))
        return n
    } else {
        return state
    }
}

let INIT_STATE_PRODUCT = localStorage.getItem(storage.product) || null
INIT_STATE_PRODUCT = INIT_STATE_PRODUCT == "null" ? null : INIT_STATE_PRODUCT

export const product = (state = INIT_STATE_PRODUCT, action) => {
    if (action.type === SET_PRODUCT) {
        if(action.saveLocal){
            localStorage.setItem(storage.product, action.payload)
        }
        const newVal = localStorage.getItem(storage.product) || action.payload
        return newVal == "null" ? null : newVal
    } else {
        return state
    }
}

export const webSidebar = (state = false, action) => {
    if (action.type === CHANGE_WEB_SIDEBAR) {
        return !state
    } else {
        return state
    }
}

export const loginFormVisible = (state = false, action) => {
    if (action.type === TOGGLE_LOGIN_FROM) {
        return !state
    } else {
        return state
    }
}

export const externalLoginLoading = (state = null, action) => {
    if (action.type === TOGGLE_EXTERNAL_LOGIN_LOADING) {
        return action.payload
    } else {
        return state
    }
}

let loc = window.location.hostname.split('.')
const LOCALE = ['localhost', 'cz'].includes(loc[loc.length - 1]) ? 'cs' : 'sk'

export const locale = (state = LOCALE, action) => {
    if (action.type === SET_LOCALE) {
        return action.payload
    }else{
        return state
    }
}

const cookie_settings = localStorage.getItem(storage.cookie_settings)
let initialCookieSettings = cookie_settings ? JSON.parse(cookie_settings) : defaultCookieSettings

export const cookieSettings = (state = initialCookieSettings, action) => {
    let newData = {}
    switch(action.type){
        case INIT_COOKIE_SETTINGS:
            newData = {
                ...state,
                cookies: action.payload,
                is_default: true,
                is_visible: false
            }
            localStorage.setItem(storage.cookie_settings, JSON.stringify(newData))

            return newData
        case SET_COOKIE_SETTINGS:
            newData = {
                ...state,
                cookies: action.payload,
                is_default: false,
                is_visible: false
            }
            localStorage.setItem(storage.cookie_settings, JSON.stringify(newData))

            return newData
        case TOGGLE_COOKIE_SETTINGS:
            newData = {
                ...state,
                is_visible: action.payload
            }
            localStorage.setItem(storage.cookie_settings, JSON.stringify(newData))
            return newData
        default:
            return state
    }
}