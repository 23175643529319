import { combineReducers } from 'redux'

import {sidebar, locale, cookieSettings, webSidebar, product, read_notifications, loginFormVisible, externalLoginLoading} from './common/settings/reducer'
import toaster from './common/toaster/reducer'
import info from './common/info/reducer'
import strings from './common/strings/reducer'
import item from './common/item/reducer'

import auth from './web/auth/reducer'
import documents from './web/documents/reducer'
import blogArticles from './web/blogArticles/reducer'
import blogList from './web/blogList/reducer'
import supportArticles from './web/supportArticles/reducer'
import supportList from './web/supportList/reducer'
import authors from './web/authors/reducer'
import authorsList from './web/authorsList/reducer'
import advicesList from './web/advicesList/reducer'
import adviceQaA from './web/adviceQaA/reducer'
import blogCategories from './web/blogCategories/reducer'
import supportCategories from './web/supportCategories/reducer'
import notifications from './web/notifications/reducer'
import footerDocuments from './web/footerDocuments/reducer'
import products from './web/products/reducer'
import cookies from './web/cookies/reducer'
import benefits from './web/benefits/reducer'
import sms from './web/sms/reducer'
import invoices from './web/invoices/reducer'
import persistentFilters from './web/persistentFilters/reducer'
import help from './web/help/reducer'

import enums from './web/enums/reducer'

import adminAuth from './admin/adminAuth/reducer'
import sequence from './admin/sequence/reducer'
import administrators from './admin/administrators/reducer'
import roles from './admin/roles/reducer'
import adminProducts from './admin/products/reducer'
import adminBlogCategories from './admin/blogCategories/reducer'
import adminSupportCategories from './admin/supportCategories/reducer'
import adminAdvices from './admin/advices/reducer'
import adminAdviceQaA from './admin/adviceQaA/reducer'
import permissions from './admin/permissions/reducer'
import adminStrings from './admin/adminStrings/reducer'
import filters from './admin/filters/reducer'
import adminCookies from './admin/cookies/reducer'
import adminDocuments from './admin/documents/reducer'
import adminNotifications from './admin/notifications/reducer'
import adminBlogArticles from './admin/blogArticles/reducer'
import adminBlogUnansweredComments from './admin/blogUnansweredComments/reducer'
import adminSupportArticles from './admin/supportArticles/reducer'
import adminSupportUnansweredComments from './admin/supportUnansweredComments/reducer'
import emailTemplates from './admin/emailTemplates/reducer'
import adminBenefits from './admin/benefits/reducer'
import adminCustomers from './admin/customers/reducer'
import adminUsers from './admin/users/reducer'
import adminSms from './admin/adminSms/reducer'
import adminInvoices from './admin/adminInvoices/reducer'
import adminSmsVouchers from './admin/adminSmsVouchers/reducer'
import functions from './admin/functions/reducer'
import modules from './admin/modules/reducer'
import expertise from './admin/expertise/reducer'
import rss from './admin/rss/reducer'
import exitScreens from './admin/exitScreens/reducer'
import adminHelp from './admin/help/reducer'
import lpMaterials from './admin/lpMaterials/reducer'
import lps from './admin/lps/reducer'
import lpOrders from './admin/lpOrders/reducer'


import adminEnums from './admin/adminEnums/reducer'
import lazyEnums from './admin/lazyEnums/reducer'

export default combineReducers({
  toaster,
  auth,
  adminAuth,
  info,
  sms,
  strings,
  item,
  sequence,
  administrators,
  roles,
  permissions,
  enums,
  adminEnums,
  lazyEnums,
  adminStrings,
  adminProducts,
  adminBlogCategories,
  adminSupportCategories,
  adminAdvices,
  adminAdviceQaA,
  filters,
  sidebar,
  product,
  webSidebar,
  locale,
  cookieSettings,
  read_notifications,
  loginFormVisible,
  externalLoginLoading,
  documents,
  blogArticles,
  blogList,
  supportArticles,
  supportList,
  authors,
  authorsList,
  advicesList,
  adviceQaA,
  blogCategories,
  supportCategories,
  notifications,
  footerDocuments,
  products,
  adminCookies,
  cookies,
  benefits,
  persistentFilters,
  help,
  adminDocuments,
  adminNotifications,
  adminBlogArticles,
  adminBlogUnansweredComments,
  adminSupportArticles,
  adminSupportUnansweredComments,
  emailTemplates,
  adminBenefits,
  adminCustomers,
  adminUsers,
  adminSms,
  adminInvoices,
  invoices,
  adminSmsVouchers,
  modules,
  functions,
  expertise,
  rss,
  exitScreens,
  adminHelp,
  lpMaterials,
  lps,
  lpOrders,
})