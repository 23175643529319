import {
    REQUEST_BLOG_ARTICLE,
    RECEIVE_BLOG_ARTICLE,
    UPDATE_BLOG_ARTICLE,
    ERROR_BLOG_ARTICLE,
    RELOAD_BLOG_ARTICLE
} from '../../types'
import _cloneDeep from 'lodash/cloneDeep'
import {initialFetchState} from "../../../constants/defaultValues"
import {loadDataRefresh} from "../../../constants/constants"
import _get from "lodash/get"

export default (state = {}, action = {}) => {
    const newState = _cloneDeep(state)

    switch(action.type) {
        case REQUEST_BLOG_ARTICLE:
            newState[action.identifier] = {
                ...initialFetchState,
                data: _get(newState, [action.identifier, 'data'], {}),
                isFetching: true
            }
            return newState
        case RECEIVE_BLOG_ARTICLE:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: action.payload,
                message: '',
                errors: {},
                validUntil: loadDataRefresh.frontendNormal + Date.now()
            }
            return newState
        case UPDATE_BLOG_ARTICLE:
            switch (action.updateType){
                case 'favourite':
                    if(newState[action.identifier]){
                        newState[action.identifier].data.favourite = !newState[action.identifier].data.favourite
                    }
                    break
                default:
                    newState[action.identifier].data = {...newState[action.identifier].data, ...action.payload}
                    break
            }

            return newState
        case RELOAD_BLOG_ARTICLE:
            Object.keys(newState).map(article => {
                newState[article].shouldReload = true
            })
            return newState
        case ERROR_BLOG_ARTICLE:
            newState[action.identifier] = {
                ...newState[action.identifier],
                isFetching: false,
                data: {},
                message: action.errors.server || '',
                errors: action.errors
            }
            return newState
        default:
            return state
    }
}